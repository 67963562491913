export const envTypes = {
  development: "development",
  staging: "staging",
  production: "production",
};

// const themes = {
//   kfcTheme: "kfcTheme",
//   pizzaTheme: "pizzaTheme",
// };
const env = envTypes.staging;
let dfpBaseUrl,
  dfpApi,
  subDomain,
  loginPath,
  domain,
  socketUrl,
  baseUrl,
  credentials,
  dfpBaseUrlV3;

export const skipLogin = env === "development" ? true : false;

if (env === envTypes.development) {
  dfpBaseUrl = "http://localhost:8002/rest/v2/realm/";
  dfpBaseUrlV3 = "http://localhost:8002/rest/v3/realm/";
  loginPath = "http://localhost:8000/login_crave";
  baseUrl = "http://localhost:8002";
  domain = "http://localhost:3000/";
  socketUrl = "ws://localhost:8002/rest/v2/realm/ws";
  credentials = "include";
}
if (env === envTypes.staging) {
  dfpBaseUrl = "https://dfp-api.nonprod.yumds.com/rest/v2/realm/";
  dfpBaseUrlV3 = "https://dfp-api.nonprod.yumds.com/rest/v3/realm/";
  loginPath = "https://dfp-api.nonprod.yumds.com/login_crave";
  socketUrl = "wss://dfp-api.nonprod.yumds.com/rest/v2/realm/ws";
  baseUrl = "https://dfp-api.nonprod.yumds.com";
  credentials = "include";
}

if (env === envTypes.production) {
  dfpBaseUrlV3 = "https://dfp-api.yumds.com/rest/v3/realm/";
  dfpBaseUrl = "https://dfp-api.yumds.com/rest/v2/realm/";
  dfpApi = "https://dfp-api.yumds.com/rest/v2/";
  // subDomain = "/dfp-admin";
  loginPath = "https://dfp-api.yumds.com/login";
  domain = "https://dfp.yumds.com/";
  socketUrl = "wss://dfp-api.yumds.com/rest/v2/realm/ws";
  baseUrl = "https://dfp-api.yumds.com";
  credentials = "include";
}
// export const currentTheme = themes.kfcTheme;
export {
  dfpBaseUrl,
  dfpBaseUrlV3,
  dfpApi,
  subDomain,
  loginPath,
  domain,
  socketUrl,
  env,
  baseUrl,
  credentials,
};
