import { useEffect, useRef, useCallback } from "react";
import { useDispatch } from "react-redux";
import { conversationApi } from "../services/conversationService";
import { addResponse } from "../services/socketSlice";

import { current } from "@reduxjs/toolkit";
import { setNewConversation } from "../services/newConversationSlice";

const useMessageHandler = (
  lastMessage,
  selectedConversation,
  myParam,
  appDispatch,
  setMsgCounter,
  setSocketConversationStarted,
  setPromt,
  msgCounterRef,
  questionIdRef,
  newCoversation
) => {
  const dispatch = useDispatch();

  const handleNewConversation = useCallback(
    (parsedData) => {
      const { conversation_id, prompt, app_id, user_id } = parsedData.data;

      appDispatch(
        conversationApi.util.updateQueryData(
          "getConversations",
          myParam ? myParam : "",
          (draftPosts) => {
            console.log(draftPosts.conversations, "draftposts");
            const conversationExists = draftPosts.conversations.some(
              (conversation) => {
                return conversation.conversation_id === conversation_id;
              }
            );

            if (!conversationExists) {
              const newConversation = {
                conversation_id,
                conversation_name: prompt,
                app_uuid: app_id,
                yum_id: user_id,
                pin: false,
                deleted: false,
              };

              draftPosts.conversations.unshift(newConversation);
              setMsgCounter(1);
            } else {
              console.log(
                "Conversation with conversation_id already exists:",
                conversation_id
              );
            }
          }
        )
      );
    },
    [appDispatch, myParam, setMsgCounter]
  );

  const handleSocketHistory = useCallback(
    (parsedData) => {
      const {
        conversation_id,
        message,
        sender,
        terminate,
        response_id,
        prompt,
        app_id,
        user_id,
        error,
      } = parsedData.data;
      console.log("🚀 ~ error:", error);

      const checkAndUpdateCache = () => {
        console.log("🚀 ~ error:", parsedData.data);

        const state = appDispatch((_, getState) => getState());
        const existingCache =
          conversationApi.endpoints.getSocketHistory.select(conversation_id)(
            state
          );

        if (existingCache && existingCache.data) {
          const data = existingCache.data;
          if (data.length === 1 && data[0].terminate) {
            dispatch(setNewConversation(true));
          }
          appDispatch(
            conversationApi.util.updateQueryData(
              "getSocketHistory",
              conversation_id,
              (draftPosts) => {
                if (draftPosts) {
                  if (msgCounterRef.current === 0) {
                    const newData = {
                      prompt,
                      conversation_name: prompt,
                      conversation_id,
                      app_id,
                      yum_id: user_id,
                      visible: terminate,
                      messages: [
                        {
                          response_id,
                          message: message.content,
                          terminate,
                          sender,
                          error,
                        },
                      ],
                      error,
                    };
                    draftPosts.push(newData);
                    setMsgCounter(1);
                  } else {
                    const newResp = {
                      response_id,
                      message: message.content,
                      terminate,
                      sender,
                      error,
                    };

                    const lastConversation = draftPosts[draftPosts.length - 1]; // Get the last conversation

                    if (lastConversation) {
                      lastConversation.messages.push(newResp); // Directly mutate the messages array
                      lastConversation.visible = terminate; // Update visibility
                    }
                  }
                }
              }
            )
          );
        } else {
          // Initialize cache entry if it doesn't exist
          const newEntry = [
            {
              prompt,
              conversation_name: prompt,
              conversation_id,
              app_id,
              yum_id: user_id,
              visible: terminate,
              messages: [
                {
                  response_id,
                  message: message.content,
                  terminate,
                  sender,
                  error,
                },
              ],
            },
          ];

          // Optionally, use dispatch to add this to the cache

          appDispatch(
            conversationApi.util.upsertQueryData(
              "getSocketHistory",
              conversation_id,
              newEntry
            )
          );
        }
      };

      checkAndUpdateCache();
    },
    [appDispatch, msgCounterRef, setMsgCounter]
  );

  const handleResponse = useCallback(
    (parsedData) => {
      const { message, sender, terminate } = parsedData.data;
      const latestQuestionId = questionIdRef.current;

      if (sender && sender !== "userproxy") {
        dispatch(
          addResponse({
            responseId: latestQuestionId,
            answer: message.content,
            sender,
            terminate,
          })
        );
      }

      if (terminate === true) {
        setSocketConversationStarted(false);
        setMsgCounter(0);
        setPromt();
      }
    },
    [
      questionIdRef,
      dispatch,
      setSocketConversationStarted,
      setMsgCounter,
      setPromt,
    ]
  );

  useEffect(() => {
    if (lastMessage) {
      const { data } = lastMessage;
      const parsedData = JSON.parse(data);
      console.log("🚀 ~ useEffect ~ parsedData:", parsedData);

      if (parsedData.data) {
        console.log(newCoversation, "newCoversation");
        if (newCoversation) {
          handleNewConversation(parsedData);
        } else if (selectedConversation) {
          handleSocketHistory(parsedData);
        }
        handleResponse(parsedData);
      } else if (parsedData.error) {
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    lastMessage,
    handleNewConversation,
    handleSocketHistory,
    handleResponse,
    newCoversation,
  ]);
};

export default useMessageHandler;
